import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Works from './Components/Works/Works2';
import Services from './Components/Services/Services';
import Contact from './Components/Contact/Contact';
import Tips from './Components/Tips/Tips';
// import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route path="/about">
          <About/>
        </Route>
        <Route path="/services">
          <Services/>
        </Route>
        <Route exact path="/works">
          <Works/>
        </Route>
        <Route path="/contact">
          <Contact/>
        </Route>
        <Route path="/tips">
          <Tips/>
        </Route>
        
        <Route exact path="/works/:category" children={<Works />}/>
        {/* <Route path="/works/:category" component={Works}/> */}

      </Switch>
    </Router>
  );
}

export default App;
