import React from 'react';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';

import './Contact.scss';

function Contact(){
    return(
        <div>
            <Menu/>
            <div className="contact-container">
                <div className="contact-blue-element">
                    <img src="/Desktop/Images/Home/Section1/Blue-element.png" alt=""/>
                </div>
                <div className="contact-middle">
                    <h3>We always work</h3>
                    <h1>CONTACT US</h1>
                    <p>
                        Send us a message. Let us know
                        about your idea and let's start
                        to work together.

                    </p>
                    <form action="">
                        <div className="input-item">
                            <input type="text" placeholder="Full Name"/>
                        </div>
                        <div className="input-item">
                            <input type="text" placeholder="Cellphone" />
                        </div>
                        <div className="input-item">
                            <input type="text" placeholder="Email"/>
                        </div>
                        <div className="input-item">
                            <textarea placeholder="Message" name="" id="" cols="30" rows="10"></textarea>
                        </div>
                        <div className="button-contact-form">
                            <button>Enviar</button>
                        </div>
                    </form>
                </div>
                <div className="contact-right-image">
                    <img src="/Desktop/Images/Contact/Header-image.png" alt=""/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Contact;