import React from 'react';
import './HomeSection4.scss';

function HomeSection4() {
    return (
        <section className="section4-container">

        <div className="container section4-wrapper">
            <h1>Recent Projects</h1>
            <div className="project-container">
                <ul className="cards">
                    <li className="service-card">
                        <img src="/Assets/holder.jpg" alt=""/>
                        <div className="service-card-content">
                            <h2>Card design woes</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur earum nemo
                                quaerat fugiat magnam autem error asperiores deleniti cum, ipsum explicabo eos
                            </p>
                            <a href="#">See more</a>
                        </div>
                    </li>
                    <li className="service-card">
                        <img src="/Assets/holder.jpg" alt=""/>
                        <div className="service-card-content">
                            <h2>Card design woes</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur earum nemo
                                quaerat fugiat magnam autem error asperiores deleniti cum, ipsum explicabo eos
                            </p>
                            <a href="#">See more</a>
                        </div>
                    </li>
                    <li className="service-card">
                        <img src="/Assets/holder.jpg" alt=""/>
                        <div className="service-card-content">
                            <h2>Card design woes</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur earum nemo
                                quaerat fugiat magnam autem error asperiores deleniti cum, ipsum explicabo eos
                            </p>
                            <a href="#">See more</a>
                        </div>
                    </li>
                </ul>
                <div className="recent-project-button-container">
                    <a href="/works">See other works</a>
                </div>
            </div>
        </div>
        </section>
    );
}

export default HomeSection4;