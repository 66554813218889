import React from 'react';
import Menu from './../Menu/Menu';
import HomeSection1 from './HomeSection1/HomeSection1';
import HomeSection2 from './HomeSection2/HomeSection2';
import HomeSection3 from './HomeSection3/HomeSection3';
import HomeSection4 from './HomeSection4/HomeSection4';
import Footer from '../Footer/Footer';

import './Home.scss';

function Home() {
    return (
        <div className="home-page">
            <Menu/>
            <HomeSection1/>
            <HomeSection2/>
            <HomeSection3/>
            <HomeSection4/>
            <Footer/>
        </div>
    );
}

export default Home;