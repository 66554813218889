import React from 'react';
import './Footer.scss';

function Footer() {
    return (
        <footer>
            <div className="footer-container container">

                <div className="footer-wrapper">
                    <div className="f-contact-us footer-item">
                        <h2>CONTACT US</h2>
                        <ul>
                            <li><a href="mailto:infos@toxiostek.com?subject=Infos&body=Message">infos@toxiostek.com</a></li>
                            <li>+1 849 639 4039</li>
                            <div className="f-social-media">
                                <a href="https://www.facebook.com/toxiostek" target="_blank"><img src="/Assets/Home/Footer/facebook.png" alt=""/></a>
                                <a href="https://www.instagram.com/toxiostek/" target="_blank"><img src="/Assets/Home/Footer/instagram.png" alt=""/></a>
                            </div>
                        </ul>
                    </div>
                    <div className="f-about-us footer-item">
                        <h2>ABOUT US</h2>
                        <ul>
                            <li>Slogan</li>
                            <li>Why Toxios</li>
                            <li>Our Vision</li>
                            <li>Our Mission</li>
                            <li>Our Core Value</li>
                        </ul>
                    </div>
                    <div className="f-our-services footer-item">
                        <h2>OUR SERVICES</h2>
                        <ul>
                            <li>Branding</li>
                            <li>Web development</li>
                            <li>Mobile development</li>
                            <li>Software development</li>
                            <li>Graphic design</li>
                            <li>Network</li>
                            <li>IT Support</li>
                        </ul>
                    </div>
                    <div className="f-our-works footer-item">
                        <h2>OUR WORKS</h2>
                        <ul>
                            <li>Projects</li>
                        </ul>

                    </div>
                    {/* <div className="f-tips footer-item">
                        <h2>TIPS</h2>
                        <ul>
                            <li>
                                <b>Development</b>
                                <ul>
                                    <li>Webiste development</li>
                                    <li>Mobile development</li>
                                    <li>Software development</li>
                                    <li>Others</li>
                                </ul>

                            </li>
                            <li>
                                <b>Dizayn</b>
                                <ul>
                                    <li>Branding</li>
                                    <li>Graphic design</li>
                                    <li>Others</li>
                                </ul>

                            </li>
                            <li>
                                <b>Network</b>
                                <ul>
                                    <li>IT support</li>
                                    <li>Administration</li>
                                </ul>

                            </li>
                        </ul>
                    </div> */}

                </div>
                <div className="f-bottom-footer footer-item">
                    <div className="">
                        <div className="copyright">
                            <p>&copy; 2019 Toxios Tek - Digital Company</p>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default Footer;