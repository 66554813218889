import React from 'react';

import './HomeSection2.scss'

function HomeSection2() {
    return (
        <section className="section2-container">

            <div className="section2-wrapper">

                <div className="section2-picture">
                    <img src="/Assets/Home/About_us/aboutus-image.png" alt=""/>
                </div>
                <div className="section2-text">
                    <div className="section2-text-wrapper">
                        <div className="ABU-row">
                            <h1>ABOUT US</h1>
                            <p>Toxios is said to have invented clay construction techniques in ancient Greece</p>
                        </div>

                        <div className="ABU-row">
                            <h3>
                                <span className="why">Why </span>
                                Toxios?
                            </h3>
                            <p>
                                we seek to create, to design better approaches for getting things done. Toxios
                                was a good example, someone who did not conform to what is as of now there. He
                                was someone who was interested in doing construction another way to improve
                                things.
                            </p>
                        </div>
                        <div className="ABU-row">
                            <h3>VISION</h3>
                            <p>
                                Become the most innovative, creative and the best technological company in the
                                Caribean
                            </p>
                        </div>

                        <div className="ABU-row">
                            <h3>Mission</h3>
                            <p>
                                Meet the highest needs and expectation of our customers by offering them
                                innovative and adaptable solutions through a team of competitive and dedicated
                                professionals.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    );
}

export default HomeSection2;