import React from 'react';

import './HomeSection3.scss';

function HomeSection3() {
    return (
        <div className="container-sm">

            <div className="services-title">
                <h1>Our Services</h1>
                <h4>We offer diversity and creativity.</h4>
                <p>
                    Your project is part of a plan that You want to execute at a specific time. You
                    like it when you have enough information to make quick decisions to handle
                    unexpected events. We understand and value your schedule so that your entire
                    plan can be executed on time.
                </p>
            </div>

            <div className="services-container">
                <div className="service-wrapper">
                    <div className="service-item service1">
                        <img src="/Assets/Home/Our_services/branding.png" alt=""/>
                        <div className="service-card">
                            <h4>Branding</h4>
                            <p>
                                How long would you like people to remember your business, your idea, your event,
                                your brand? We can make your brand last and make an impact. Great processes with
                                great people always get great results.
                            </p>

                        </div>
                    </div>
                    <div className="service-item service2">
                        <img src="/Assets/Home/Our_services/graphic-design.png" alt=""/>
                        <div className="service-card">
                            <h4>Graphic Design</h4>
                            <p>
                                How long would you like people to remember your business, your idea, your event,
                                your brand? We can make your brand last and make an impact. Great processes with
                                great people always get great results.
                            </p>
                        </div>
                    </div>
                    <div className="service-item service3">
                        <img src="/Assets/Home/Our_services/graphic-design.png" alt=""/>
                        <div className="service-card">
                            <h4>Web development</h4>
                            <p>
                                How long would you like people to remember your business, your idea, your event,
                                your brand? We can make your brand last and make an impact. Great processes with
                                great people always get great results.
                            </p>

                        </div>
                    </div>
                    <div className="service-item services-button">
                        <a href="/services">Learn more</a>
                    </div>
                </div>
            </div>

            <div className="message-services">
                <p>
                    "Bringing brands to life online"<br/>
                    is our company mantra for a reason.
                </p>
            </div>
        </div>
    );
}

export default HomeSection3;