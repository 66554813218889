import React from 'react';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';

function Tips(){
    return(
        <div>
            <Menu/>
            <Footer/>  
        </div>
    )
}

export default Tips;