import React from 'react';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import Service from './Service/Service';
import Services_file from './Services_file.json';

import './Services.scss';

function Services() {
    let our_services = [];
    let i = 0;

    for (var service in Services_file) {
        // console.log("Value of I: ", i);
        if ((i + 1) % 2 !== 0) {
            // console.log("this is the Even");
            our_services.push(<Service
                key={i}
                side={"left"}
                title={Services_file[service].title}
                description={Services_file[service].description}
                process={Services_file[service].process}
                url={Services_file[service].url}/>)
        } else {
            // console.log("this is the Odd");
            our_services.push(<Service
                key={i}
                side={"right"}
                title={Services_file[service].title}
                description={Services_file[service].description}
                process={Services_file[service].process}
                url={Services_file[service].url}/>)
        }
        i++;
    }
    return (
        <div className="service-page">
            <Menu/>
            <div className="SV-container">
                <div className="SV-blue-element">
                    <img className="blue" src="/Assets/Home/Header_elements/blue-element.png" alt=""/>
                    <img className="circle-up" src="/Assets/Services/Body/circle1.png" alt=""/>
                    <img className="circle-down" src="/Assets/Services/Body/circle1.png" alt=""/>
                    <img className="circle-up-small" src="/Assets/Services/Body/circle1.png" alt=""/>
                </div>
                <div className="SV-header-wrapper">
                    <h4>We work</h4>
                    <h1>Closely with</h1>
                    <h1>Our customers</h1>
                    <p>
                        To understand their needs and satisfy them in the most modern and creative way.
                    </p>
                </div>
                <div className="circles-2">
                    <img className="circle2-blue" src="/Assets/Services/Body/circle1.png" alt=""/>
                    <img className="circle2-orange1" src="/Assets/Services/Body/circle2.png" alt=""/>
                    <img className="circle2-orange2" src="/Assets/Services/Body/circle2.png" alt=""/>
                </div>

            </div>
            {our_services}
            <Footer/>
        </div>
    );
}

export default Services;