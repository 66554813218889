import React from 'react';
import { Link } from 'react-router-dom';
import './Service.scss';

function Service(props) {
    let service;
    let processes = [];
    let parameter = "";

    // build the parameter for the view works in specific section
    if(props.title.split(" ").length > 1){
        parameter = props.title.split(" ").join("-").toLowerCase();
        // console.log("this is parameter with -: ", parameter);
    }else{
        parameter = props.title.toLowerCase();
        // console.log("this is parameter without -: ", parameter);
    
    }
    for (let proc of props.process) {
        processes.push(
            <li key={proc}>{proc}</li>
        );
    }
    if (props.side === "left") {
        service = (
            <div className="service-container left-service-container">
                <div className="img-service service-item">
                    <div className="test">
                        <img src={props.url} alt=""/>
                    </div>
                </div>
                <div className="service-content service-item">
                    <div className="service-content-wrapper">
                        <h1>{props.title}</h1>
                        <p>
                            {props.description}
                        </p>
                        <ul>
                            {processes}
                        </ul>
                        <Link to={"/works/"+parameter} className="left">View Works</Link>
                </div>
                    <div className="service-content-img">
                        <img src="/Assets/Services/Body/right.png" alt=""/>
                    </div>
                </div>

            </div>
        );
    } else if (props.side === "right") {
        service = (
            <div className="service-container right-service-container">
                <div className="service-content service-item">
                    <div className="service-content-img">
                        <img src="/Assets/Services/Body/left.png" alt=""/>
                    </div>
                    <div className="service-content-wrapper">
                        <h1>{props.title}</h1>
                        <p>
                            {props.description}
                        </p>
                        <ul>
                            {processes}
                        </ul>
                        <Link to={"/works/"+parameter} className="right">View Works</Link>
                    </div>
                </div>
                <div className="img-service service-item">
                    <div className="test">
                        <img src={props.url} alt=""/>
                    </div>
                </div>
            </div>
        );
    }

    return (service);
}

export default Service;