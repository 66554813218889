import React, { useState} from "react";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import {useParams, useHistory} from 'react-router-dom';

import "./Works.scss";
import works_file from "./works_file.json";

// let filtered_work_data = []; console.log("this is the work file json:
// ",works_file);

//filtered work by category
function getWorks(category) {
    let works_filtered = [];
    let works_data = works_file.works;
    if (category.toLowerCase() === "All Projects".toLowerCase()) {
        works_filtered = works_data;

    } else {
        for (var i = 0; i < works_data.length; i++) {
            // console.log(   "the condition is: ",
            // works_data[i].hover.category.toLowerCase() === category.toLowerCase() );
            if (works_data[i].hover.category.toLowerCase() === category.toLowerCase()) {
                //   console.log("in the condition");
                works_filtered.push(works_data[i]);
            }
        }
    }
    console.log("This is the split value with - in getworks: ", category.split("-").length, "category: ", category);
    if(category.split(" ") > 1){
        console.log("version superior to 1: ");
        // history.push("/works/"+category.split(" ").join("-"));
    }else{
        console.log("version inferior to 2: ");
        // history.push("/works/"+category.split(" ").join("-"));
    }
    return works_filtered;
}

//get the category when they click on submenu
function getCategory(e) {
    e.preventDefault();
    let category = e.target.innerText;
    console.log("This is the split value with - in getcategory: ", category.split("-").length,"category: ", category);
    // this.getWorks(category);
    // filtered_work_data = getWorks(category); console.log("this is filtered: ",
    // this.filtered_work_data); return getWorks(category);
    return category;
}

function buildWorkList(works){
    let work_lines = [];
    let work_items = [];


    for (var i = 0; i < works.length; i++) {
        if ((i + 1) % 3 === 0) {
            // console.log("Im in the (i+1)%3 condition");
            work_items.push(
                <div key={i} className="work-item hvrbox">
                    <img
                        src={works[i].picture}
                        alt=""
                        className="hvrbox-layer_bottom"/>
                    <div className="hover-work-content hvrbox-layer_top">
                        <div className="hvrbox-text">
                            <h3 className="hover-work-category">
                                {works[i].hover.category}
                            </h3>
                            <h3 className="hover-work-project-name">
                                / {works[i].hover.title}
                            </h3>
                        </div>
                    </div>
                </div>
            );
            if (((i + 1) / 3) % 2 === 0) {
                //   console.log("Im in the (i+1)/3 % 2 condition");
                work_lines.push(
                    <div key={i} className="work-line-container work-line-orange">
                        {work_items}
                    </div>
                );
            } else {
                work_lines.push(
                    <div key={i} className="work-line-container work-line-blue">
                        {work_items}
                    </div>
                );
            }
            work_items = [];
        } else {
            work_items.push(
                <div key={i} className="work-item hvrbox">
                    <img
                        src={works[i].picture}
                        alt=""
                        className="hvrbox-layer_bottom"/>
                    <div className="hover-work-content hvrbox-layer_top">
                        <div className="hvrbox-text">
                            <h3 className="hover-work-category">
                                {works[i].hover.category}
                            </h3>
                            <h3 className="hover-work-project-name">
                                / {works[i].hover.title}
                            </h3>
                        </div>
                    </div>
                </div>
            );
        }

        if (works.length % 3 > 0 && i === works.length - 1) {
            work_lines.push(
                <div key={i} className="work-line-container work-line-blue">
                    {work_items}
                </div>
            );
        }
    }

    /*End line of for loop */
    return work_lines;

}

function changeUrl(history, category){
    let url = "/works/"+category.split(" ").join("-");
    history.push(url);
}

function Works(){
    // console.log("This is the props: ", this.props);
    const [works, setWorksData] = useState(works_file.works); 
    let {category} = useParams();
    let history = useHistory();
    let work_lines = [];
    // let works = works_file.works;
    if(category){
        // console.log("This is the parameters", category.toLowerCase().split("-").join(" "));
        // console.log(history);    
        work_lines = buildWorkList(getWorks(category.toLowerCase().split("-").join(" ")));
    }else{
        // console.log("category is not ready", category.split("-").join(" "));
        work_lines = buildWorkList(works);
    }

    return(
        <div>
            <Menu/>
            <div className="work-container">
                <div className="work-section1">
                    <div className="SV-blue-element">
                        <img
                            className="blue"
                            src="/Assets/Home/Header_elements/blue-element.png"
                            alt=""/>
                        <img className="circle-up" src="/Assets/Services/Body/circle1.png" alt=""/>
                        <img className="circle-down" src="/Assets/Services/Body/circle1.png" alt=""/>
                        <img
                            className="circle-up-small"
                            src="/Assets/Services/Body/circle1.png"
                            alt=""/>
                    </div>
                    {/* <div className="work-section1-picture">
                    <img src="/Desktop/Images/Common/Blue-element.png" alt=""/>
                </div> */}
                    <div className="work-section1-content">
                        <h3>WE LOVE</h3>
                        <h1>WORKS</h1>
                        <h1>AND GOOD RESULTS</h1>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere sapiente, at
                            esse dolorem, delectus quis consequuntur necessitatibus ea veniam tempora
                            debitis sed qui blanditiis voluptas velit. Quaerat, reiciendis animi. Non.
                        </p>
                    </div>
                    <div className="circles-2">
                        <img className="circle2-blue" src="/Assets/Services/Body/circle1.png" alt=""/>
                        <img
                            className="circle2-orange1"
                            src="/Assets/Services/Body/circle2.png"
                            alt=""/>
                        <img
                            className="circle2-orange2"
                            src="/Assets/Services/Body/circle2.png"
                            alt=""/>
                    </div>
                </div>

                <div className="work-section2">
                    <div className="works-submenu-container">
                        <ul className="works-submenu">
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    {" "}
                                    All Projects
                                </a>
                            </li>
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    Web Development
                                </a>
                            </li>
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    Mobile Development
                                </a>
                            </li>
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    Software Development
                                </a>
                            </li>
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    ERP
                                </a>
                            </li>
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    Branding
                                </a>
                            </li>
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    Graphic Design
                                </a>
                            </li>
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    IT Support
                                </a>
                            </li>
                            <li>
                                <a onClick={(event)=> changeUrl(history,getCategory(event))} href="#">
                                    Network
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="works-list">{work_lines}</div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}



export default Works;
