import React from 'react';


import './HomeSection1.scss';

function HomeSection1(){
    return(
        <section className="section1-container">
            <div className="section1-wrapper">
                <div>
                    <img src="/Desktop/Images/Common/Blue-element.png" alt=""/>
                </div>
                <div className="HS-text-content">
                    <h3>
                        <span className="we-are">WE ARE </span><span className="digital">DIGITAL</span> AGENCY
                    </h3>
                    <h2>TOXIOS TEK</h2>
                    <h4>Constantly <span className="innovating">innovating</span> for you</h4>
                    <p>
                        We are a digital agency. We love to do that extra that give us better result for
                        your project.
                    </p>
                    <div className="H-button">
                        <a href="/works" className="b-view-more">View more</a >
                        <a href="/contact" className="b-contact-us">Contact us</a >
                    </div>

                </div>
                <div className="HS-right-image">
                    <div className="circle3"></div>
                    <img src="/Desktop/Images/Home/Section1/Header-image.png" alt=""/>
                </div>
            </div>

        </section>
    );
}

export default HomeSection1;