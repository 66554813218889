import React from 'react';
import "./Menu.scss";

function Menu() {
    return (
        <nav className="nav-container">
            <div className="nav-wrapper container">
                <div>
                    <img src="/logo.png" alt=""/>
                </div>
                <div className="menu-list">
                    <ul>
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/about">About</a>
                        </li>
                        <li>
                            <a href="/services">Services</a>
                        </li>
                        <li>
                            <a href="/works">Works</a>
                        </li>
                        <li>
                            <a href="/contact">Contact</a>
                        </li>
                        {/* <li>
                            <a href="/tips">Tips</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Menu;