import React from 'react';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import './About.scss';

function About(){
    return(
        <div className="about-container">
            <Menu/>
            <div className="about-wrapper">
            <section className="about-section1-container">
                <div className="section1-blue-element">
                    <img src="/Desktop/Images/About/Section1/Blue-element.png" alt=""/>
                </div>
                <div className="section1-center">
                    <div className="section1-center-img">
                        <img src="/Desktop/Images/About/Section1/brain.png" alt=""/>
                    </div>
                    <div className="section1-center-title">
                        <h1>Constantly innovating for you.</h1>
                        <p>
                            Toxios is said to have invented clay
                            construction techniques in ancient Greece.
                        </p>
                    </div>
                </div>
                <div className="talking-picture">
                    <img src="/Desktop/Images/About/Section1/talking.png" alt=""/>
                </div>
            </section>

            <section className="about-section2-container">
                <div className="about-section2-left">
                    <h3>REASON</h3>
                    <h1>Why Toxios?</h1>
                    <p>
                        We seek to create, to design better approaches for getting things done. Toxios
                        was a good example, someone who did not conform to what is as of now there. He
                        was someone who was interested in doing construction another way to improve
                        things. And as we like to build diversely in other to improve things, we feel
                        associated with Toxios.
                    </p>
                    {/* <div className="about-section2-col about-section2-left-wrapper">
                    </div> */}
                </div>
                <div className="about-section2-right">
                    <div>
                        <h2>
                            Our vision
                        </h2>
                        <p>
                            Become the most innovative, creative and the best technological company in the
                            caribbean
                        </p>
                    </div>
                    <div>
                        <h2>
                            Our mission
                        </h2>
                        <p>
                            Meet the highest need of expectation of our customers by offering them
                            innovative and adaptable solutions through a team of competitive and dedicated
                            professionals.
                        </p>
                    </div>
                    {/* <div className="about-section2-right-wrapper">
                    </div> */}

                </div>
            </section>

            <section className="about-section3-container">
                <div className="about-section3-wrapper">
                    <div className="about-section3-left">
                        <div className="about-section3-left-wrapper">
                            <h3>VALUE</h3>
                            <h1>Our core value</h1>
                        </div>
                    </div>
                    <div className="core-value-grid">
                        <div className="row row1">
                            <div className="col1 cell">
                                <h3>Self-criticism</h3>
                            </div>
                            <div className="col2 cell">
                                <h3>Honest</h3>
                            </div>
                            <div className="col3 cell">
                                <h3>Curious</h3>
                            </div>
                        </div>
                        <div className="row row2 ">
                            <div className="col1 cell">
                                <h3>Live to serve</h3>
                            </div>
                            <div className="col2 cell middle-cell">
                                <h3>Creative</h3>
                            </div>
                            <div className="col3 cell">
                                <h3>Communication</h3>
                            </div>
                        </div>
                        <div className="row row3">
                            <div className="col1 cell">
                                <h3>Care</h3>
                            </div>
                            <div className="col2 cell">
                                <h3>Fun</h3>
                            </div>
                            <div className="col3 cell">
                                <h3>Bold</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            </div>
            <Footer/>

        </div>
    );
}


export default About;